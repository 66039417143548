html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  line-height: $root-line-height;
  color: $body-color;
  background-color: $body-bg;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: $link-color;
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid $border-color;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid $border-color;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: $gray-1;
}

ol, ul {
  list-style-position: inside;
  padding: 0rem 1rem;
}

ol {
    counter-reset: item;
}
ol li { display: block }

ol li:before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
}

::selection {
  background: $blue; /* WebKit/Blink Browsers */
  color: $body-bg;
}
::-moz-selection {
  background: $blue; /* Gecko Browsers */
  color: $body-bg;
}

// image followed by em for captioning
img + em {
  font-size: 75%;
}
