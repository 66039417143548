// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 50rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-left: auto;
  margin-right: auto;
  font-size: $small-font-size;
  margin-top: 4rem;
  margin-bottom: 1rem;
  .nav-links {
    margin: 0;
    padding: 0;
    li {
      padding: 0 5px 0 0;
    }
  }
}
