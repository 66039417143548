// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.masthead-title {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-4;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    opacity: .5;
  }
}

.nav-links {
  padding: 0;
  display: inline-block;
  li {
    display: inline-block;
    padding: 0 10px;
  }
}

.nav-twitter {
  display: inline-block;
  width: 1.25rem;
  padding-top: 4px;
  vertical-align: middle;
  color: $link-color;

  svg {
    fill: $link-color;
  }
}
