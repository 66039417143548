// Typography
//
// Headings, body text, lists, and other misc typographic elements.

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.25;
  color: #313131;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p.p-short {
  margin-bottom: 0rem;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted #e5e5e5;
  }
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}


// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

// Custom type
//
// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

i, em {
  font-style: italic;
}

b, strong {
  color: #303030;
  font-weight: bold;
}
