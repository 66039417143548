// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: $code-font-family;
}

code {
  padding: .15em .25em;
  background-color: #fbf1c7;
  color: #427b58;
  border-radius: 3px;
  font-weight: 400;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

// Pygments via Jekyll
.highlight {
  font-weight: 300; // inconsolata light 300
  padding: 0.75rem;
  margin-bottom: 1rem;
  /* font-size: .8rem; */
  line-height: 1.4;
  /* background-color: #f9f9f9; */
  border-radius: .25rem;

  code {
    font-weight: 300; // inconsolata light 300
  }

  pre {
    margin-bottom: 0;
    overflow-x: auto;
  }

  .lineno {
    display: inline-block; // Ensures the null space also isn't selectable
    padding-right: .75rem;
    padding-left: .25rem;
    color: #999;
    // Make sure numbers aren't selectable
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}


// Gist via GitHub Pages
// .gist .gist-file {
//   font-family: Menlo, Monaco, "Courier New", monospace !important;
// }
// .gist .markdown-body {
//   padding: 15px;
// }
// .gist pre {
//   padding: 0;
//   background-color: transparent;
// }
// .gist .gist-file .gist-data {
//   font-size: .8rem !important;
//   line-height: 1.4;
// }
// .gist code {
//   padding: 0;
//   color: inherit;
//   background-color: transparent;
//   border-radius: 0;
// }
